import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
require('dotenv').config()

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_APIKEY,
//   authDomain: "thenetworktap-acb37.firebaseapp.com",
//   projectId: "thenetworktap-acb37",
//   storageBucket: "thenetworktap-acb37.appspot.com",s
//   messagingSenderId: "358052831931",
//   appId: "1:358052831931:web:17fada6b4ac53e5e047f9e",
//   measurementId: "G-P93LVHK0L6"
// };


initializeApp({
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID
})
export const db = getFirestore()