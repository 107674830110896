<template>
  <div class="m-auto mt-5" style="font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif!important;">
    <div>
      <!-- <button @click="showModal = true">Open Modal</button> -->
      <div v-if="showModal">
        <div class="popup" style="text-align:center">  
          <div class="popup-inner" style="position: relative;">
            <button  class="icon-button" @click="showModal = false" >
              <i class="fa fa-times" style="color:black !important;  position: absolute; top:20px;right:20px"></i>
            </button>
            <div>
              <canvas ref="qrcodeImg"></canvas>
              <p>Scan To Find Me!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="backside-bg d-block d-sm-none"></div>
    <div class="col-12 container-left-area">
      <div class="profile-photo text-center position-inherit profile-photo-container">
        <button class="icon-button position-absolute" @click="openModal" style="top:10px; right:50px">
            <i class="fa fa-qrcode" style="color:black !important;"></i>
        </button>
        <button  class="icon-button  position-absolute" @click="shareLink" style="top:10px; right:10px" >
          <i class="fa fa-share-alt" style="color:black !important; "></i>
      </button>
        <img
         :src="profileImg"
          alt="profile image"
        />
        <div class="profile-name fw-bold my-2">{{this.name}}</div>
        <div class="profile-description my-2" v-if="this.companyName!='' && this.role!=''">
          {{this.role}} @ {{this.companyName}}
        </div>
        <div class="profile-description my-2" v-else-if="this.companyName=='' && this.role!=''">
          {{this.role}}
        </div>
        
        <div class="profile-description my-2" v-else-if="this.companyName !='' && this.role==''">
          {{this.companyName}} 
        </div>
        <div class="profile-gmail my-2" v-if="this.companyName!=''"><a :href="'mailto:' + this.email">{{ this.email }}</a></div>

        <div class="profile-photo-icon">
          <i class="fa-brands fa-facebook"  v-if="this.fb != '' &&  this.fb!= undefined" v-on:click="pushIcon('fb')"></i> 
          <i class="fa fa-instagram" style="font-size:22px" v-if="this.insta != '' &&  this.insta!= undefined" v-on:click="pushIcon('insta')"></i>
          <i class="fa fa-linkedin" v-if="this.linkedin != '' &&  this.linkedin!= undefined" v-on:click="pushIcon('linkedin')"></i> 
          <i class="fa fa-twitter" v-if="this.twitter != '' &&  this.twitter!= undefined" v-on:click="pushIcon('twitter')"></i>
          <i class="fa fa-globe" v-if="this.website != '' &&  this.website!= undefined" v-on:click="pushIcon('web')"></i> 
          <i class="fa fa-youtube" v-if="this.youtube != '' &&  this.youtube!= undefined" v-on:click="pushIcon('youtube')"></i>
          <i class="fa fa-snapchat" v-if="this.snapchat != '' &&  this.snapchat!= undefined" v-on:click="pushIcon('snapchat')"></i> 
          <i class="fab fa-tiktok" v-if="this.tiktok != '' &&  this.tiktok!= undefined" v-on:click="pushIcon('tiktok')" ></i>
          <a v-if="this.whatsapp != '' &&  this.whatsapp!= undefined" :href="'https://wa.me/'+this.whatsapp"><i class="fa fa-whatsapp"></i></a>
          <a v-if="this.tele != '' &&  this.tele!= undefined" :href="'https://telegram.me/'+this.tele"><i class="fa fa-telegram"></i></a>
        </div>
      </div>
      <div class="backside-bg d-none d-sm-block"></div>
    </div>
    <div class="col-12 mx-auto profile-container">
      <a :href="'tel:' + this.contactNumber"  v-if="this.contactNumber != ''  &&  this.contactNumber!= undefined">

        <div class="profile-info d-flex my-2" >

            <i class="fa-solid fa-square-phone"></i>

            <div class="mx-2" >
                <div class="fw-bold profile-info-title">Mobile Number</div>
                <div class="profile-info-description" >{{this.contactNumber}}</div>
            </div>

        </div>
      </a>
      <a :href="'tel:' + this.officeNumber" v-if="this.officeNumber != ''  &&  this.officeNumber!= undefined" >
        <div class="profile-info d-flex my-2"  >
          <!-- <i class="fa-sharp fa-solid fa-phone-office"></i> -->
            <i class="fa-solid fa-phone"></i>
            <div class="mx-2">
              <div class="fw-bold profile-info-title">Office Number</div>
              <div class="profile-info-description">{{this.officeNumber}}</div>
            </div>
        </div>  
      </a>
      <a  v-if="this.faxNumber != '' &&  this.faxNumber!= undefined" :href="'tel:' + this.faxNumber">
        <div class="profile-info d-flex my-2">
          <i class="fa-solid fa-fax"></i>
          <div class="mx-2">
            <div class="fw-bold profile-info-title">Fax Number</div>
            <div class="profile-info-description">{{this.faxNumber}}</div>
          </div>
        </div>
      </a>
      <a :href="'https://www.google.com/maps/place/' + this.address" target="_blank">
        <div class="profile-info d-flex my-2" v-if="this.address != '' &&  this.address!= undefined">
          <i class="fa-solid fa-location-dot"></i>
          <div class="mx-2">
            <div class="fw-bold profile-info-title">Address</div>
            <div class="profile-info-description">
              {{this.address}}
            </div>
          </div>
        </div>
      </a>
      <div class="profile-info d-flex my-2" v-if="this.description != '' &&  this.description!= undefined">
        <i class="fa-solid fa-note-sticky"></i>
        <div class="mx-2">
          <div class="fw-bold profile-info-title">Description</div>
          <div class="profile-info-description">
            {{this.description}}
          </div>
        </div>
      </div>
      <button class="add-btn d-flex my-5"  v-on:click="saveContact()">
        <div class="mx-2 ">Add to contact  <i class="fa-solid fa-plus"></i></div>
      </button>
    </div>
  </div>
</template>


<script>
import QRious from 'qrious'

export default {
    props:{
        companyNameComponent: String,
        contactNumberComponent: String,
        emailComponent: String,
        fbComponent: String,
        instaComponent: String,
        linkedinComponent: String,
        nameComponent: String,
        profileImgComponent: String,
        roleComponent: String,
        twitterComponent: String,
        websiteComponent: String,
        addressComponent: String,
        officeNumberComponent: String,
        faxNumberComponent: String,
        descriptionComponent: String,
        youtubeComponent: String,
        tiktokComponent: String,
        whatsappComponent: String,
        teleComponent: String,
        snapchatComponent: String,
        picComponent: String,
        socialArrComponent: Array,
        counterComponent:Number
        
        },
    
    data() {
        return {
          companyName: this.companyNameComponent,
          contactNumber: this.contactNumberComponent,
          email: this.emailComponent,
          fb: this.fbComponent,
          insta: this.instaComponent,
          linkedin: this.linkedinComponent,
          name: this.nameComponent,
          profileImg: this.profileImgComponent,
          role: this.roleComponent,
          twitter: this.twitterComponent,
          website: this.websiteComponent,
          address: this.addressComponent,
          officeNumber:this.officeNumberComponent,
          faxNumber:this.faxNumberComponent,
          description:this.descriptionComponent,
          youtube:this.youtubeComponent,
          tiktok:this.tiktokComponent,
          whatsapp:this.whatsappComponent,
          tele:this.teleComponent,
          snapchat:this.snapchatComponent,
          pic:this.pic,
          socialArr:this.socialArrComponent,
          counter:this.counterComponent,
          showModal:false,
          
        };
    },
        
       
    
    methods: {
      async shareLink(){
        if (navigator.share) {
            try {
                const title = "Add Contact";
                const text = "Hey, let's connect! Please add me to your contacts using this link:";
                const url = window.location.href;

                await navigator.share({
                    title: title,
                    text: text,
                    url: url
                });
                console.log("Content shared successfully.");
            } catch (error) {
                console.error("Error sharing content:", error);
            }
        } else {
            // Fallback for browsers that do not support the Web Share API
            console.log("Web Share API is not supported in this browser.");
        }
      },
      openModal(){
        this.showModal = true;
        this.$nextTick(() => {
            new QRious({
              element: this.$refs.qrcodeImg,
              value: window.location.href,
              size: 300,
            });
        })
   
      },
        pushIcon(icon) {
        if (icon == "fb") {
            var urlfb = this.fb;
            if (!urlfb.match(/^https?:\/\//i)) {
            urlfb = "https://" + urlfb;
            }
            window.open(urlfb, "_blank");
        } else if (icon == "twitter") {
            var urltwitter = this.twitter;
            if (!urltwitter.match(/^https?:\/\//i)) {
            urltwitter = "https://" + urltwitter;
            }
            window.open(urltwitter, "_blank");
        } else if (icon == "insta") {
            var urlinsta = this.insta;
            if (!urlinsta.match(/^https?:\/\//i)) {
            urlinsta = "https://" + urlinsta;
            }
            window.open(urlinsta, "_blank");
        } else if (icon == "linkedin") {
            var urllinkedin = this.linkedin;
            if (!urllinkedin.match(/^https?:\/\//i)) {
            urllinkedin = "https://" + urllinkedin;
            }
            window.open(urllinkedin, "_blank");
        } else if(icon == "web"){
            var urlwebsite = this.website;
            if (!urlwebsite.match(/^https?:\/\//i)) {
            urlwebsite = "https://" + urlwebsite;
            }
            window.open(urlwebsite, "_blank");
        } else if(icon == "snapchat"){
            var snapchat = this.snapchat;
            if (!snapchat.match(/^https?:\/\//i)) {
            snapchat = "https://" + snapchat;
            }
            window.open(snapchat, "_blank");
        } else if(icon == "tiktok"){
            var tiktok = this.tiktok;
            if (!tiktok.match(/^https?:\/\//i)) {
            tiktok = "https://" + tiktok;
            }
            window.open(tiktok, "_blank");
        }else if(icon == "youtube"){
            var youtube = this.youtube;
            if (!youtube.match(/^https?:\/\//i)) {
            youtube = "https://" + youtube;
            }
            window.open(youtube, "_blank");
        }
        
        },
        
        async saveContact() {
        
            const toDataURL = url => fetch(url)
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
                }))
            
            await toDataURL(this.profileImg)
                .then(dataUrl => {
                var pic = dataUrl
                var strImage = pic.replace(/^data:image\/[a-z]+;base64,/, "");

                // var urlwebsite = this.website;
            // if (!urlwebsite.match(/^https?:\/\//i)) {
            //     urlwebsite = "https://" + urlwebsite;
            // }
            var vCardsJS = require("vcards-js");
            var vCard = vCardsJS();
            vCard.lastName = this.name;
            vCard.organization = this.companyName;
            vCard.cellPhone = this.contactNumber;
            vCard.workPhone = this.officeNumber;
            vCard.workFax = this.faxNumber;
            vCard.title = this.role;
            vCard.email = this.email;
            vCard.url = window.location.href;
            vCard.photo.embedFromString(strImage, 'image/png')
            // vCard.photo.embedFromString(getBase64Img,'image/jpeg');
            // if (urlwebsite != "https://"){
            //     vCard.url = urlwebsite
            // }
            vCard.note = this.description
            var vCardString = vCard.getFormattedString();
            var url =
                "data:text/vcard;charset=utf-8," + encodeURIComponent(vCardString);
            document.location.href = url;

                })

            
        
        
        },
    },
    watch: {
      companyNameComponent: function (newValue) {
          this.companyName = newValue;
      },
      contactNumberComponent: function (newValue) {
          this.contactNumber = newValue;
      },
      emailComponent: function (newValue) {
          this.email = newValue;
      },
        fbComponent: function (newValue) {
          this.fb = newValue;
      },
        instaComponent: function (newValue) {
          this.insta = newValue;
      },
        linkedinComponent: function (newValue) {
          this.linkedin = newValue;
      },
        nameComponent: function (newValue) {
          this.name = newValue;
      },
        profileImgComponent: function (newValue) {
          this.profileImg = newValue;
      },
        roleComponent: function (newValue) {
          this.role = newValue;
      },
        twitterComponent: function (newValue) {
          this.twitter = newValue;
      },
        websiteComponent: function (newValue) {
          this.website = newValue;
      },
        addressComponent: function (newValue) {
          this.address = newValue;
      },
        officeNumberComponent: function (newValue) {
          this.officeNumber = newValue;
      },
        faxNumberComponent: function (newValue) {
          this.faxNumber = newValue;
      },
        descriptionComponent: function (newValue) {
          this.description = newValue;
      },
        youtubeComponent: function (newValue) {
          this.youtube = newValue;
      },
        tiktokComponent: function (newValue) {
          this.tiktok = newValue;
      },
        whatsappComponent: function (newValue) {
          this.whatsapp = newValue;
      },
        teleComponent: function (newValue) {
          this.tele = newValue;
      },
        snapchatComponent: function (newValue) {
          this.snapchat = newValue;
      },
        picComponent: function (newValue) {
          this.pic = newValue;
      },
        socialArrComponent: function (newValue) {
          this.socialArr = newValue;
      },
        counterComponent: function (newValue) {
          this.counter = newValue;
      },
}
};
</script>

<style scoped>

.profile-photo {
  /* max-width: 450px; */
  margin: auto;
  padding: 48px 0;
  background: #ffffff;
  border-radius: 12px;
  position: inherit;
  z-index: 11;
}
.profile-photo img {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  border: 3px solid #919394;
  object-fit: cover;

}
.profile-description {
  font-size: 14px;
}
.profile-gmail {
  font-size: 13px;
  color: #bec0c1;
}

.profile-info {
  border-radius: 12px;
  background: #ffffff;
  padding: 8px;
}
.profile-info-title {
  /* font-family: "Circular-Loom"; */
  font-family: "Century Gothic",CenturyGothic,AppleGothic,sans-serif!important;
  color: #2b3344;
}
.profile-info-description {
  font-size: 14px;
  color: #a5a5a5;
  font-weight: 500;
}
.add-btn {
  border: none;
  text-transform: uppercase;
  outline: none;
  font-size: 17px;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  color: white;
  background: #0247c9;
  justify-content: center;
}
.profile-photo-icon {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}
.profile-photo-icon svg,
.profile-photo-icon i {
  color: white;
  background: #108ef1;
  border-radius: 50%;
  padding: 8px;
  font-size: 20px;
  margin: 5px 12px;
}

.profile-info svg,
.profile-info i {
  background: #dee7f6;
  color: #0447ca;
  padding: 12px;
  border-radius: 8px;
  font-size: 20px;
  max-height: 50px;
}
.backside-bg {
  position: absolute;
  display: block;
  height: 250px;
  width: 100%;
  background: #0447ca;
  z-index: 10;
  top: -3rem;
  border-radius: 0 0 132px 0px;
  left: 0;
}
.container-left-area {
  position: relative;
}
@media screen and (max-width: 500px) {
  .backside-bg {
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0 0 60px 0px;
  }
}
a {
  text-decoration: none;
  color: #565656;
  }

.icon-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.5rem;
  color: black !important;
  padding: 0.5rem;
}

.icon-button i {
  display: block;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}
  .popup .popup-inner {
    background: #fff;
    padding: 32px;
}
.profile-container{
  width: 40%;
  padding: 0 3rem !important;
}
@media (max-width:960px) {
  .profile-container {
    width: 100%;
    padding: 0 2rem !important;
  }
}
.profile-photo-container{
  margin: 0 4rem !important;
}
@media (max-width:960px) {
  .profile-photo-container {
    margin: 0 2rem !important;
  }
}
</style>
