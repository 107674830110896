<template>
    <div class="main">
        <div>
            <!-- <button @click="showModal = true">Open Modal</button> -->
            <div v-if="showModal">
                <div class="popup" style="text-align:center">  
                <div class="popup-inner" style="position: relative;">
                    <button  class="icon-button" @click="showModal = false" >
                    <i class="fa fa-times" style="color:white !important;  position: absolute; top:20px;right:20px"></i>
                    </button>
                    <div>
                      <canvas ref="qrcodeImg"></canvas>
                      <p style="color:white;">Scan To Find Me!</p>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="row" style="background-color: inherit;">
            <div >
                <div class="row">
                       <div>
                           <button class="icon-button" @click="openModal">
                                <i class="fa fa-qrcode" style="color:white !important; margin-left:30px; margin-top:30px"></i>
                            </button>
                            <button  class="icon-button" @click="shareLink" >
                                <i class="fa fa-share-alt" style="color:black !important; color:white !important;"></i>
                            </button>
                        </div>
                    

                        <div class="card text-center">
              <img
                class="card-img-top img-circle rounded-circle"
                :src="profileImg"
                alt="Card image cap"
              />
              <div class="card-block mt-5">
                <h3 class="card-title">{{ this.name }}</h3>
                <!-- <p class="card-position">
                  {{ this.role }} at {{ this.companyName }}
                </p> -->
                <div class="role-team">
                  <div class="row mt-4">
                    <div v-if="this.companyName!='' && this.role!=''">
                        <h6>{{this.role}} @ {{this.companyName}}</h6>
                    </div>
                    <div v-else-if="this.companyName=='' && this.role!=''">
                        <h6>{{this.role}} </h6>
                    </div>
                    <div v-else-if="this.companyName !='' && this.role==''">
                         <h6>{{this.companyName}}</h6>
                    </div>
                    <!-- <div class="col border-right">
                      <div class="profile-role">
                        <span>Company</span>
                        <h6>{{ this.companyName }}</h6>
                      </div>
                    </div>
                    <div class="col">
                      <div class="profile-team">
                        <span>Role</span>
                        <h6>{{ this.role }}</h6>
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="profile-contact">
                  <div v-if ="this.counter <=5">
                    <div class="profile-contact-links">
                      <div v-if="this.fb != '' &&  this.fb!= undefined" class="profile-contact-link">
                        <a v-on:click="pushIcon('fb')">
                          <i class="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.insta != '' &&  this.insta!= undefined" class="profile-contact-link">
                        <a v-on:click="pushIcon('insta')">
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div
                        v-if="this.linkedin != '' &&  this.linkedin!= undefined"
                        class="profile-contact-link"
                      >
                        <a v-on:click="pushIcon('linkedin')">
                          <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.twitter != '' &&  this.twitter!= undefined" class="profile-contact-link">
                        <a v-on:click="pushIcon('twitter')">
                          <i class="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.website != '' &&  this.website!= undefined" class="profile-contact-link">
                        <a v-on:click="pushIcon('web')">
                          <i class="fa fa-globe" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.youtube != '' &&  this.youtube!= undefined" class="profile-contact-link">
                        <a v-on:click="pushIcon('youtube')">
                          <i class="fa fa-youtube" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.snapchat != '' &&  this.snapchat!= undefined" class="profile-contact-link">
                        <a v-on:click="pushIcon('snapchat')">
                          <i class="fa fa-snapchat" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.tiktok != '' &&  this.tiktok!= undefined" class="profile-contact-link">
                        <a v-on:click="pushIcon('tiktok')">
                          <i class="fab fa-tiktok" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.whatsapp != '' &&  this.whatsapp!= undefined" class="profile-contact-link">
                        <a :href="'https://wa.me/'+this.whatsapp">
                          <i class="fa fa-whatsapp" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.tele != '' &&  this.tele!= undefined" class="profile-contact-link">
                        <a :href="'https://telegram.me/'+this.tele">
                          <i class="fa fa-telegram" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                      <div class="profile-contact-links" >
                        <div v-for="social in socialArr.slice(0,5)" :key="social">
                          
                          <div v-if="social=='fb'" class="profile-contact-link">
                            <a v-on:click="pushIcon('fb')">
                              <i class="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='insta'" class="profile-contact-link">
                            <a v-on:click="pushIcon('insta')">
                              <i class="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div
                            v-if="social=='linkedin'"
                            class="profile-contact-link"
                          >
                            <a v-on:click="pushIcon('linkedin')">
                              <i class="fa fa-linkedin" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='twitter'" class="profile-contact-link">
                            <a v-on:click="pushIcon('twitter')">
                              <i class="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='web'" class="profile-contact-link">
                            <a v-on:click="pushIcon('web')">
                              <i class="fa fa-globe" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='youtube'" class="profile-contact-link">
                            <a v-on:click="pushIcon('youtube')">
                              <i class="fa fa-youtube" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='snapchat'" class="profile-contact-link">
                            <a v-on:click="pushIcon('snapchat')">
                              <i class="fa fa-snapchat" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='tiktok'" class="profile-contact-link">
                            <a v-on:click="pushIcon('tiktok')">
                              <i class="fab fa-tiktok" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='whatsapp'" class="profile-contact-link">
                            <a :href="'https://wa.me/'+this.whatsapp">
                              <i class="fa fa-whatsapp" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='tele'" class="profile-contact-link">
                            <a :href="'https://telegram.me/'+this.tele">
                              <i class="fa fa-telegram" aria-hidden="true"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="profile-contact-links" style="margin-top:20px">
                        <div v-for="social in socialArr.slice(5)"  :key="social"> 
                          
                          <div v-if="social=='fb'" class="profile-contact-link">
                            <a v-on:click="pushIcon('fb')">
                              <i class="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='insta'" class="profile-contact-link">
                            <a v-on:click="pushIcon('insta')">
                              <i class="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div
                            v-if="social=='linkedin'"
                            class="profile-contact-link"
                          >
                            <a v-on:click="pushIcon('linkedin')">
                              <i class="fa fa-linkedin" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='twitter'" class="profile-contact-link">
                            <a v-on:click="pushIcon('twitter')">
                              <i class="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='web'" class="profile-contact-link">
                            <a v-on:click="pushIcon('web')">
                              <i class="fa fa-globe" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='youtube'" class="profile-contact-link">
                            <a v-on:click="pushIcon('youtube')">
                              <i class="fa fa-youtube" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='snapchat'" class="profile-contact-link">
                            <a v-on:click="pushIcon('snapchat')">
                              <i class="fa fa-snapchat" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='tiktok'" class="profile-contact-link">
                            <a v-on:click="pushIcon('tiktok')">
                              <i class="fab fa-tiktok" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='whatsapp'" class="profile-contact-link">
                            <a :href="'https://wa.me/'+this.whatsapp">
                              <i class="fa fa-whatsapp" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div v-if="social=='tele'" class="profile-contact-link">
                            <a :href="'https://telegram.me/'+this.tele">
                              <i class="fa fa-telegram" aria-hidden="true"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
                </div>
            </div>
            <div >
                <div class="profile-info">
                    <div class="profile-info-wrapper">
                    <div class="profile-detail" v-if="this.contactNumber != ''  &&  this.contactNumber!= undefined">
                        <div class="profile-detail-title" >
                        <span>Mobile No.</span>
                        </div>
                        <div class="profile-detail-text">
                        <h6>
                            <a :href="'tel:' + this.contactNumber">{{
                            this.contactNumber
                            }}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="profile-detail" v-if="this.officeNumber != ''  &&  this.officeNumber!= undefined">
                        <div class="profile-detail-title" >
                        <span>Office No.</span>
                        </div>
                        <div class="profile-detail-text">
                        <h6>
                            <a :href="'tel:' + this.officeNumber">{{
                            this.officeNumber
                            }}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="profile-detail" v-if="this.faxNumber != '' &&  this.faxNumber!= undefined">
                        <div class="profile-detail-title" >
                        <span>Fax No.</span>
                        </div>
                        <div class="profile-detail-text">
                        <h6>
                            <a :href="'tel:' + this.faxNumber">{{
                            this.faxNumber
                            }}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="profile-detail"  v-if="this.email != '' &&  this.email!= undefined">
                        <div class="profile-detail-title">
                        <span>Email.</span>
                        </div>
                        <div class="profile-detail-text">
                        <h6>
                            <a :href="'mailto:' + this.email">{{ this.email }}</a>
                        </h6>
                        </div>
                    </div>
                    <div class="profile-detail" v-if="this.address != '' &&  this.address!= undefined">
                        <div class="profile-detail-title">
                        <span>Address.</span>
                        </div>
                        <div class="profile-detail-text">
                        <h6>
                            <a
                            :href="
                                'https://www.google.com/maps/place/' + this.address
                            "
                            target="_blank"
                            >{{ this.address }}</a
                            >
                        </h6>
                        </div>
                    </div>
                    <div class="profile-detail" v-if="this.description != '' &&  this.description!= undefined">
                        <div class="profile-detail-title">
                        <span>Description.</span>
                        </div>
                        <div class="profile-detail-text">
                        <h6 style="font-weight: normal;">
                            {{ this.description }}
                        </h6>
                        </div>
                    </div>
                    </div>
                     <button class="btn btn-light"  v-on:click="saveContact()" style="border-radius:100px; color: #f8c441; width:100%">
                           <div class="mx-2">Add to contact </div>
                    </button>
                </div>
                   
            </div>
            
        </div>
    </div>
</template>
<script>
import QRious from 'qrious'


export default {
    
  props:{
        companyNameComponent: String,
        contactNumberComponent: String,
        emailComponent: String,
        fbComponent: String,
        instaComponent: String,
        linkedinComponent: String,
        nameComponent: String,
        profileImgComponent: String,
        roleComponent: String,
        twitterComponent: String,
        websiteComponent: String,
        addressComponent: String,
        officeNumberComponent: String,
        faxNumberComponent: String,
        descriptionComponent: String,
        youtubeComponent: String,
        tiktokComponent: String,
        whatsappComponent: String,
        teleComponent: String,
        snapchatComponent: String,
        picComponent: String,
        socialArrComponent: Array,
        counterComponent: Number,
        },
    
    data() {
        return {
          companyName: this.companyNameComponent,
          contactNumber: this.contactNumberComponent,
          email: this.emailComponent,
          fb: this.fbComponent,
          insta: this.instaComponent,
          linkedin: this.linkedinComponent,
          name: this.nameComponent,
          profileImg: this.profileImgComponent,
          role: this.roleComponent,
          twitter: this.twitterComponent,
          website: this.websiteComponent,
          address: this.addressComponent,
          officeNumber:this.officeNumberComponent,
          faxNumber:this.faxNumberComponent,
          description:this.descriptionComponent,
          youtube:this.youtubeComponent,
          tiktok:this.tiktokComponent,
          whatsapp:this.whatsappComponent,
          tele:this.teleComponent,
          snapchat:this.snapchatComponent,
          pic:this.pic,
          socialArr:this.socialArrComponent,
          counter:this.counterComponent,
          showModal:false,
          

        };
    },
    mounted(){
        console.log("here")
    },
    methods:{
      async shareLink(){
          if (navigator.share) {
              try {
                  const title = "Add Contact";
                  const text = "Hey, let's connect! Please add me to your contacts using this link:";
                  const url = window.location.href;

                  await navigator.share({
                      title: title,
                      text: text,
                      url: url
                  });
                  console.log("Content shared successfully.");
              } catch (error) {
                  console.error("Error sharing content:", error);
              }
          } else {
              // Fallback for browsers that do not support the Web Share API
              console.log("Web Share API is not supported in this browser.");
          }
      },
         openModal(){
            this.showModal = true;
            this.$nextTick(() => {
                new QRious({
                element: this.$refs.qrcodeImg,
                value: window.location.href,
                size: 300,
                foreground: "#f8c441",
                background: "#232323"
                });
            })
    
        },
        pushIcon(icon) {
        if (icon == "fb") {
            var urlfb = this.fb;
            if (!urlfb.match(/^https?:\/\//i)) {
            urlfb = "https://" + urlfb;
            }
            window.open(urlfb, "_blank");
        } else if (icon == "twitter") {
            var urltwitter = this.twitter;
            if (!urltwitter.match(/^https?:\/\//i)) {
            urltwitter = "https://" + urltwitter;
            }
            window.open(urltwitter, "_blank");
        } else if (icon == "insta") {
            var urlinsta = this.insta;
            if (!urlinsta.match(/^https?:\/\//i)) {
            urlinsta = "https://" + urlinsta;
            }
            window.open(urlinsta, "_blank");
        } else if (icon == "linkedin") {
            var urllinkedin = this.linkedin;
            if (!urllinkedin.match(/^https?:\/\//i)) {
            urllinkedin = "https://" + urllinkedin;
            }
            window.open(urllinkedin, "_blank");
        } else if(icon == "web"){
            var urlwebsite = this.website;
            if (!urlwebsite.match(/^https?:\/\//i)) {
            urlwebsite = "https://" + urlwebsite;
            }
            window.open(urlwebsite, "_blank");
        } else if(icon == "snapchat"){
            var snapchat = this.snapchat;
            if (!snapchat.match(/^https?:\/\//i)) {
            snapchat = "https://" + snapchat;
            }
            window.open(snapchat, "_blank");
        } else if(icon == "tiktok"){
            var tiktok = this.tiktok;
            if (!tiktok.match(/^https?:\/\//i)) {
            tiktok = "https://" + tiktok;
            }
            window.open(tiktok, "_blank");
        }else if(icon == "youtube"){
            var youtube = this.youtube;
            if (!youtube.match(/^https?:\/\//i)) {
            youtube = "https://" + youtube;
            }
            window.open(youtube, "_blank");
        }
        
        },
        
        async saveContact() {
        
            const toDataURL = url => fetch(url)
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
                }))
            
            await toDataURL(this.profileImg)
                .then(dataUrl => {
                var pic = dataUrl
                var strImage = pic.replace(/^data:image\/[a-z]+;base64,/, "");

            //     var urlwebsite = this.website;
            // if (!urlwebsite.match(/^https?:\/\//i)) {
            //     urlwebsite = "https://" + urlwebsite;
            // }
            var vCardsJS = require("vcards-js");
            var vCard = vCardsJS();
            vCard.lastName = this.name;
            vCard.organization = this.companyName;
            vCard.cellPhone = this.contactNumber;
            vCard.workPhone = this.officeNumber;
            vCard.workFax = this.faxNumber;
            vCard.title = this.role;
            vCard.email = this.email;
            vCard.url = window.location.href;
            vCard.photo.embedFromString(strImage, 'image/png')
            // vCard.photo.embedFromString(getBase64Img,'image/jpeg');
            // if (urlwebsite != "https://"){
            //     vCard.url = urlwebsite
            // }
            vCard.note = this.description
            var vCardString = vCard.getFormattedString();
            var url =
                "data:text/vcard;charset=utf-8," + encodeURIComponent(vCardString);
            document.location.href = url;

                })

            
        
        
        },
    }
        
}
</script>
<style scoped>


.main{
    background: #232323 ;
    min-height: 100vh;
    color: white;
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    overflow-y: auto; /* or overflow: scroll; */
}
a{
    text-decoration: none;
    color:white;
}
.icon-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.5rem;
  color: black !important;
  padding: 0.5rem;
}

.icon-button i {
  display: block;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}
  .popup .popup-inner {
    background: #232323;
    padding: 32px;
}
 .card{
  border-radius: 10px;
  background-color: inherit;
  color:#f8c441
 }

.profile-header {
  align-items: center;
}

.card-img-top {
  object-fit: cover;
  position: absolute;
  top: -100px;
  left: 50%;
  margin-left: -100px;
  width: 200px !important;
  height: 200px !important;
}

.card {
  margin-top: 100px;
  padding: 80px 30px 40px 30px;
  border: none;
  /* border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px; */
}

.profile-role span,
.profile-team span,
.profile-detail-title span {
  font-size: 13px;
  /* color: #b1b1b1; */
  text-transform: uppercase;
  font-size: 600;
  color: #f8c441;

}

.profile-role h6,
.profile-team h6 {
  /* color: ; */
  font-weight: 500;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
}

.profile-contact {
  margin-top: 35px;
}

.profile-contact-links {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;

}

.profile-contact-link {
  border-radius: 30px;
  padding: 10px 15px;
  background: white;
  animation: name-of-animation 5s infinite;
  /* width: 50px; */
  height: 45px;
  width: 45px;
  color: #f8c441;

}

.profile-contact-link {
  cursor: pointer;
  color: #f8c441;


}

i {
  color: #f8c441;
}

.profile-info {
  margin-top: 0px;
  padding: 0 25px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.save-contact {
  padding: 0 25px;
}

.profile-detail {
  margin-bottom: 15px;
}

/* @media screen and (min-width: 800px) {
    .profile-info{
        margin-top:10% ;
    }
} */
 body {
    background-color: #232323 !important;
    overflow-x:hidden;
  }

</style>